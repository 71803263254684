<template>
    <div class="number_pagination_wrap" v-if="paginationData.total > 0 && this.returnLastPage() > 1">
        <div class="page_btn_wrap el-page" v-if="returnLastPage() > 1">
            <button class="first_end" @click="nowPage = 1"></button>
            <button class="previous" @click="nowPage -= 1"></button>
            <el-pagination
                :hide-on-single-page=true
                :page-size="paginationData.size"
                :current-page.sync="page"
                :pager-count="11"
                :total="100"
                @current-change="changePage('page', page)"
                class="page_btn_wrap"
                layout="pager"
            >
            </el-pagination>
            <button class="next" @click="nowPage += 1"></button>
            <button class="last_end" ></button>

        </div>
    </div>
</template>

<script>
export default {
    name: "LazyloadLoadingLayout",
    mixins: [],
    components: {
    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {
        paginationData: {default: {page: 1, size: 10, total: 0,}},
    },
    data() {
        return{
            nowPage: 1,
            pageLists: 5,
            page: 1,
            maxPage: 0,
        }
    },
    beforeRouterEnter() {},
    created() {
    },
    mounted() {
        this.maxPage = Math.ceil(this.paginationData.total / this.paginationData.size + 1)
    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{
        changePage(dataName, pageList) {
            // this[dataName] = !this[dataName];
            this[dataName] = pageList;
            if(this[dataName] < 1 ) {
                this[dataName] = 1;
            } else {
                this[dataName] = pageList;
            }
        },
        returnLastPage() {
            return Math.ceil(this.paginationData.total/this.paginationData.size);
        },
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
